.panda-hanging {
  top: 69%;
  left: -20%;
  width: 40px;
  height: 40px;
}
.opacity_70 {
  opacity: 70%;
}
.opacity_90 {
  opacity: 90%;
}

.logo {
  z-index: 10;
}
.nav-hidden {
  transition: 0.6s ease-in-out;
  position: fixed;
  right: -100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  z-index: 3;
}
.nav-open {
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  gap: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  transition: 0.6s ease-out !important;
  z-index: 3;
}
.nav-open li a {
  color: var(--clr-green) !important;
}
.hamburger {
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 10;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: var(--clr-green);
  z-index: 10;
}
.hamburger-2 .portfolio-bar {
  display: block;
  width: 25px;
  height: 3px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: var(--clr-green);
  z-index: 10;
}
.portfolio-bar {
  display: block;
  width: 25px;
  height: 3px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #fff;
  z-index: 10;
}
.hamburger-2 {
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 10;
}
.hamburger-2 .bar:nth-child(2) {
  opacity: 0;
  z-index: 10;
}
.hamburger-2 .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  z-index: 10;
}
.hamburger-2 .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  z-index: 10;
}
.zindex {
  z-index: 10;
  position: relative;
}
@media (min-width: 767.99px) {
  .nav-hidden {
    transition: 0.6s ease-in-out;
    position: static;
    right: -100%;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: unset;
    width: 75%;
    min-height: unset;
    text-align: unset;
  }
}
@media (min-width: 991.99px) {
  .navbar-width {
    width: 75%;
  }
}

.font-nav {
  font-weight: 600 !important;
  color: var(--clr-green) !important;
  opacity: 100 !important;
}
