@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Petrona:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Aclonica&family=Cinzel:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=Bungee+Shade&display=swap");
* {
  list-style-type: none !important;
  text-decoration: none !important;
}

.container {
  max-width: 1240px !important;
}
.ff_inter {
  font-family: "Inter", sans-serif;
}
.ff_lato {
  font-family: "Lato", sans-serif;
}
.ff_petrona {
  font-family: "Petrona", serif;
}
.ff_baijam {
  font-family: "Bai Jamjuree", sans-serif;
}
.ff_poppins {
  font-family: "Poppins", sans-serif;
}
.ff_abel {
  font-family: "Abel", serif;
}
.ff_aclonica {
  font-family: "Aclonica", serif;
}
.ff_cinzel {
  font-family: "Cinzel", serif;
}
.ff_bungee {
  font-family: "Bungee Shade", serif;
}
.ff_birthstone {
  font-family: "Birthstone", serif;
}

@font-face {
  font-family: "Gallient";
  src: url("./assets/fonts/Gallient.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.ff_gallient {
  font-family: "Gallient", sans-serif;
}

.cursor_pointer {
  cursor: pointer;
}

:root {
  /* FONT SIZES */
  --fs-xxsm: 14px;
  --fs-xsm: 15px;
  --fs-sm: 16px;
  --fs-md: 20px;
  --fs-lg: 28px;
  --fs-xl: 38px;
  --fs-xxl: 40px;
  --fs-2xl: 50px;
  --fs-48px: 28px;
  --fs-98px: 48px;
  --fs-80px: 46px;
  --fs-md-2: 23px;

  /* COLORS */
  --clr-green: #2f5b4c;
  --clr-green-2: #2b6705;
  --clr-green-3: #336c10;
  --clr-light-black: #333333;
  --clr-black-2: #020202;
  --clr-grey: #d9d9d9;
  --clr-grey-2: #e2e2e2;
  --clr-dark-grey: #718096;
  --clr-soil: #e2dcac;
  --clr-dark-blue: #2d3748;
}

.fs_16 {
  font-size: var(--fs-xxsm);
}
.fs_18 {
  font-size: var(--fs-xsm);
}
.fs_20 {
  font-size: var(--fs-sm);
}
.fs_23 {
  font-size: var(--fs-md-2);
}
.fs_32 {
  font-size: var(--fs-md);
}
.fs_40 {
  font-size: var(--fs-lg);
}
.fs_48 {
  font-size: var(--fs-48px);
}
.fs_64 {
  font-size: var(--fs-xl);
}
.fs_70 {
  font-size: var(--fs-xxl);
}
.fs_80 {
  font-size: var(--fs-2xl);
}
.fs_98 {
  font-size: var(--fs-98px);
}
.fs_80_invoice {
  font-size: var(--fs-80px);
}

/* COLORS */
.clr_green {
  color: var(--clr-green);
}
.clr_dark_grey {
  color: var(--clr-dark-grey);
}
.clr_dark_blue {
  color: var(--clr-dark-blue);
}
.clr_green_2 {
  color: var(--clr-green-2);
}
.clr_black_2 {
  color: var(--clr-black-2);
}
.clr_light_black {
  color: var(--clr-light-black);
}
.bg_clr_soil {
  background-color: var(--clr-soil);
}
.bg_clr_green_3 {
  background-color: var(--clr-green-3);
}

/* FONT WEIGHT */
.fw-800 {
  font-weight: 800;
}

@media (min-width: 575.99px) {
  :root {
    /* FONT SIZES */
    --fs-xxsm: 14px;
    --fs-xsm: 15px;
    --fs-sm: 17px;
    --fs-md: 22px;
    --fs-lg: 32px;
    --fs-xl: 42px;
    --fs-xxl: 50px;
    --fs-2xl: 60px;
    --fs-80px: 60px;
    --fs-48px: 48px;
    --fs-48px: 32px;
    --fs-98px: 58px;
  }
}
@media (min-width: 767.99px) {
  :root {
    /* FONT SIZES */
    --fs-xxsm: 14px;
    --fs-xsm: 16px;
    --fs-sm: 18px;
    --fs-md: 24px;
    --fs-lg: 32px;
    --fs-xl: 48px;
    --fs-xxl: 60px;
    --fs-80px: 70px;
    --fs-48px: 36px;
    --fs-98px: 78px;
  }
}
@media (min-width: 991.99px) {
  :root {
    /* FONT SIZES */
    --fs-xxsm: 16px;
    --fs-xsm: 18px;
    --fs-sm: 20px;
    --fs-md: 32px;
    --fs-lg: 40px;
    --fs-xl: 64px;
    --fs-xxl: 70px;
    --fs-80px: 80px;
    --fs-48px: 48px;
    --fs-98px: 98px;
  }
  .height-100vh {
    min-height: 100vh;
  }
}
