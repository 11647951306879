.service-example-card {
  width: 250px;
  height: 250px;
}
.service-examples {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.service-card-overlay {
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  opacity: 0%;
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.hover-effect:hover .service-card-overlay {
  z-index: 1;
  opacity: 50%;
}
.hover-effect:hover .service-examples {
  z-index: 2;
}
.services-font-size {
  font-size: 7px;
}
@media (min-width: 575.99px) {
  .services-font-size {
    font-size: 10px;
  }
}
@media (min-width: 767.99px) {
  .services-font-size {
    font-size: 22px;
  }
}
