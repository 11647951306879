.fw-800 {
  font-weight: 800;
}
.hero_para_width {
  max-width: 530px;
}
.bamboo-sticks {
  right: 0%;
  top: 0%;
  width: 300px;
  height: 100vh;
  z-index: -1;
}
.panda {
  right: 2%;
  top: 50%;
  transform: translateY(-60%);
  rotate: 4deg;
  animation: panda 1s linear;
}
.dot-border {
  width: 25px;
  height: 50px;
  border: 1px solid #444234;
  border-radius: 45px;
  padding: 2px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30%;
  transition: 0.3s ease-in-out;
}
.green-dot {
  min-width: 20px;
  height: 20px;
  left: 50%;
  top: 4%;
  transform: translateX(-50%);
  border-radius: 100%;
  background-color: var(--clr-green-2);
}
.dot-active {
  animation: greendot 0.3s ease-in-out;
  top: 54%;
}
@keyframes greendot {
  0% {
    top: 4%;
  }
  100% {
    top: 54%;
  }
}
@keyframes panda {
  0% {
    top: 0;
    right: 12%;
  }
  100% {
    top: 50%;
  }
}
