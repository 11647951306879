.btn-read-more {
  background-color: transparent;
  border: 1px solid #2b6705;
  max-width: 252px;
  width: 100%;
  height: 58px;
  border-radius: 6.65px;
  color: #2b6705;
  transition: 0.2s ease-in-out;
}
.btn-read-more:hover {
  background-color: #2b6705;
  border: 1px solid #2b6705;
  color: #ffffff;
}
.btn-read-more:hover .unhover-img {
  display: none;
}
.btn-read-more:hover .hover-img {
  display: block;
}
.hover-img {
  display: none;
}

.service-cards {
  background-color: #e2dcac;
  border: 1px solid #424245;
}

.image-bg-circle {
  background-color: #2b6705;
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

@media (min-width: 767.99px) {
  .service-image {
    height: 420px;
    width: 60% !important;
  }
}
@media (min-width: 991.99px) {
  .service-image {
    height: 100%;
  }
}
