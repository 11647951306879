.footer_logo {
  width: 244px;
  height: 120px;
}
.footer-social-icons-bg {
  width: 38px;
  border-radius: 100%;
  height: 38px;
  background-color: #ffffff;
}
.opacity_60 {
  opacity: 60%;
}
.footer-overlay {
  background: linear-gradient(
    180deg,
    rgba(43, 103, 5, 0.8) 0%,
    rgba(38, 84, 10, 0.8) 54.01%,
    rgba(13, 30, 2, 0.8) 70.94%,
    rgba(5, 12, 0, 0.8) 100%
  );
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}

.hr-line {
  border: 1px solid #ffffff26;
}
