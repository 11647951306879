.who-we-are-bg {
  background-image: url("../../assets/images/png/bambooBg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  position: relative;
}
.who-we-are-bg-service {
  background-image: url("../../assets/images/png/bambooBg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
}
.who-we-are .bamboo-sticks {
  left: 0%;
  top: 3%;
  width: 180px;
  height: 120vh;
  z-index: -1;
}
.who-we-are .panda {
  left: -4%;
  top: 11%;
  transform: rotateZ(-15deg);
  animation: panda2 1s linear;
}
.who-we-are .btn-next {
  width: 34px;
  height: 34px;
  border: 1px solid white;
  border-radius: 100%;
  background-color: transparent;
}
.slider-card {
  background-color: #d9d9d908;
  border-radius: 8px;
}
@keyframes panda2 {
  0% {
    left: 1%;
    top: 0%;
  }
  100% {
    top: 30%;
  }
}

@media (max-width: 576px) {
  .who-we-are-bg {
    background-size: cover;
  }
}
