.client-cards {
  border: 1.08px solid;
  border-image-source: linear-gradient(
    0deg,
    rgba(123, 62, 255, 0.2) 0%,
    rgba(140, 120, 255, 0.17) 106.02%
  );
  box-shadow: 0px 10.76px 20.55px 0px #00000005;
  background-color: #ffffff;
  border-radius: 10px;
  color: var(--clr-black-2);
  transition: 0.3s ease-in-out;
}
.client-cards:hover {
  background-color: var(--clr-green-2);
  color: #ffffff;
}

.what-our-client-say .bamboo-sticks {
  z-index: 1;
  top: -32%;
  right: 0%;
  height: 900px !important;
}
.what-our-client-say .panda {
  right: -1%;
  z-index: 2;
}
.opacity_80 {
  opacity: 80%;
}

.what-our-client-say .btn-slider-prev,
.what-our-client-say .btn-slider-next {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #2b6705;
  border: 0;
}
@media (min-width: 767.99px) {
  .client-say-para {
    max-width: 360px;
  }
}
