.blog-1 {
  height: 100%;
  box-shadow: 0px 5.9px 4.72px 0px #dddddd40;
  background-color: #2b6705;
  border: 1.18px solid #e2e2e2;
  border-radius: 12px;
}

.blog-2-3-cards {
  border-radius: 6px;
  box-shadow: 0px 4.72px 4.72px 0px #c0bdbd40;
  background: #ffffff;
}

@media (min-width: 767.99px) {
  .person-img {
    object-position: center;
    height: 290px;
  }
  .blog-1 {
    height: 290px;
    box-shadow: 0px 5.9px 4.72px 0px #dddddd40;
    background-color: #2b6705;
    border: 1.18px solid #e2e2e2;
    border-radius: 12px;
  }
}
