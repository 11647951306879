.icons-bg {
  background: #ffffff0d;
  border: 0.5px solid #0000000d;
  min-height: 50px;
  min-width: 50px;
  border-radius: 100%;
}

.social-icons-bg {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
}

.footer-upper {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .footer-upper {
    margin-top: -108px;
  }
}
@media (max-width: 576px) {
  .footer-upper {
    margin-top: -108px;
  }
}
