.key-points-ul li {
  list-style-type: disc !important;
}

.why-choose-us-cards {
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background-color: #498908;
}
.why-choose-us-cards:hover {
  box-shadow: 3px 3px 18px var(--clr-green-3);
}
