.get-in-touch-footer-bg {
  background-image: url("/src/assets/images/png/contactBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.get-in-touch-footer-bg-service {
  background-image: url("/src/assets/images/png/contactBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.contact-bg {
  background-image: url("/src/assets/images/png/contactBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.width-unset {
  width: unset !important;
}
.values-environment-bg {
  border-radius: 6px;
  background-color: #6e9a41;
  padding: 24px;
}
.detail-hero-img {
  height: 450px;
  object-fit: cover;
  object-position: center;
}
.adtect-img {
  height: 450px;
  object-fit: contain;
}
.blogs-img {
  height: 200px;
}
/* SLICK SLIDERS */
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
}
.slick-slide > div {
  display: flex;
}

/* INVOICE */

.side-bar-menu ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.bg-blur-side-items {
  backdrop-filter: blur(100px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(255, 255, 255, 0.4) 40%
  );
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid white;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bg-blur-cards {
  backdrop-filter: blur(100px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(255, 255, 255, 0.4) 50%
  );
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid white;
  border-bottom-right-radius: 114px;
}
.bg-blur-investment {
  backdrop-filter: blur(100px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(255, 255, 255, 0.4) 46%
  );
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid white;
}

.invoice-class li {
  list-style-type: disc !important;
}
.investment-sec li {
  list-style-type: disc !important;
}

.hr-line-investment {
  height: 2px;
  background: #fff !important;
  border: unset !important;
  width: 100%;
}

.doon-school-images {
  transition: transform 0.3s ease-in-out, z-index 0s ease-in-out;
  max-width: 278px;
}

.doon-school-images:hover {
  z-index: 6;
  transform: scale(1.1);
}

.mobilReelMockup {
  max-width: 271px;
}

.reel {
  position: "absolute";
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 250px;
  max-height: 385px;
  object-fit: cover;
}

.doonphonemockup {
  position: absolute;
  max-width: 400px;
  top: 50%;
  left: 4%;
  z-index: 5;
  transform: translateY(-50%);
}

.portfolio-bg {
  background-image: url("./assets/images/png/portfolioBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.our-client-bg {
  background-image: url("./assets/images/png/ourClientBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-angular {
  background-image: url("./assets/images/png/angular.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.heading-text {
  filter: drop-shadow(2px 0 rgba(244, 244, 244, 0.6));
}
.company-img-shadow {
  filter: drop-shadow(0px 12px 22px rgba(0, 0, 0, 0.397));
}
.social-media-heading {
  transform: rotate(43deg) translate(20%, 450px);
  font-size: 98px;
}
.our-result-heading {
  font-size: 98px;
}

.statue-custom-margin {
  padding-bottom: 16rem;
}

.contact-us-img {
  max-width: 250px;
  bottom: -12%;
  right: 0;
}
@media (max-width: 576px) {
  .social-media-heading {
    transform: rotate(43deg) translate(20%, 120px);
    font-size: 43px;
  }
  .our-result-heading {
    transform: rotate(25deg) translate(20%, -40px);
    font-size: 28px;
    position: absolute;
  }
  .statue-custom-margin {
    padding-bottom: 15rem;
  }
  .contact-us-img {
    max-width: 120px;
    bottom: -22%;
    right: 0;
  }
}
@media (max-width: 400px) {
  .social-media-heading {
    transform: rotate(43deg) translate(20%, 120px);
    font-size: 40px;
  }
}

.angular-img {
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .blogs-img {
    height: 100%;
  }
  .contact-bg {
    background-image: url("/src/assets/images/png/contactBg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mobilReelMockup {
    max-width: 201px;
  }
  .reel {
    top: 60%;
    left: 50%;
    max-width: 185px;
    max-height: 290px;
    object-fit: cover;
  }
  .doon-school-images {
    transition: transform 0.3s ease-in-out, z-index 0s ease-in-out;
    max-width: 188px;
  }
  .doonphonemockup {
    position: relative;
    max-width: 200px;
    z-index: 5;
  }
}
@media (max-width: 576px) {
  .get-in-touch-footer-bg {
    background-size: cover;
    background-position: center;
  }
  .detail-hero-img {
    height: 100%;
  }
  .doon-school-images {
    max-width: 128px;
  }
}
